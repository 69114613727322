import React from "react";
import { HiHome, HiTerminal } from "react-icons/hi";
import { IoRocket, IoRibbon } from "react-icons/io5";
import { GiSkills } from "react-icons/gi";
import { FaGraduationCap } from "react-icons/fa";
import { RiAtLine } from "react-icons/ri";

import "./navigation-bar.css";
import { PageProps } from "../../shared/models/PageModel";

interface NavigationBarProps extends PageProps {
  activeNav: string;
}

const NavigationBar = ({ activeNav, updateActiveNav }: NavigationBarProps) => {
	const checkAndUpdateActiveNav = (nav: string) => {
		if (updateActiveNav) {
			updateActiveNav(nav);
		}
		return;
	};

	return (
		<nav className="nav">
			<a
				href="#"
				title="Back to top"
				onClick={() => checkAndUpdateActiveNav("#")}
				className={activeNav === "#" ? "active" : ""}
			>
				<HiHome />
			</a>
			{/* TODO: add skills section to 'Experience' */}
			<a
				href="#experience"
				title="Experience"
				onClick={() => checkAndUpdateActiveNav("#experience")}
				className={activeNav === "#experience" ? "active" : ""}
			>
				<HiTerminal />
			</a>
			<a
				href="#projects"
				title="Projects"
				onClick={() => checkAndUpdateActiveNav("#projects")}
				className={activeNav === "#projects" ? "active" : ""}
			>
				<IoRocket />
			</a>
			{/* <a href="#skills" onClick={() => checkAndUpdateActiveNav("#skills")} className={activeNav === "#skills" ? "active" : ""}><GiSkills /></a> */}
			{/* <a href="#certificates" onClick={() => checkAndUpdateActiveNav("#certificates")} className={activeNav === "#certificates" ? "active" : ""}><IoRibbon /></a> */}

			{/* TODO: add certificates section to 'Education' */}
			<a
				href="#education"
				title="Education"
				onClick={() => checkAndUpdateActiveNav("#education")}
				className={activeNav === "#education" ? "active" : ""}
			>
				<FaGraduationCap />
			</a>
			<a
				href="#contact"
				title="Contact"
				onClick={() => checkAndUpdateActiveNav("#contact")}
				className={activeNav === "#contact" ? "active" : ""}
			>
				<RiAtLine />
			</a>
		</nav>
	);
};

export default NavigationBar;
