// TODO: use URL interface
export function isInternal(url: string): boolean {
	if (process.env.PUBLIC_URL) {
		return url.startsWith(process.env.PUBLIC_URL) || isRelative(url);
	}
	console.info(`Could not determine whether ${url} is internal or not because PUBLIC_URL environment variable is ${process.env.PUBLIC_URL}.`);
	return false;
}

export function isRelative(url: string): boolean {
	return url.startsWith("/") || url.startsWith("#");
}
