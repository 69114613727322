import { Asset } from "contentful";

export function transform(asset: Asset): Asset {
	const transformed = {...asset};
	const assetFields = asset.fields;

	Object.assign(transformed, {
		title: assetFields.title,
		description: assetFields.description,
		contentType: assetFields.file.contentType,
		fileName: assetFields.file.fileName,
		url: assetFields.file.url,
		details: assetFields.file.details,
	});

	return transformed;
}
