import React, { useRef, useEffect } from "react";

import "./contact.css";
import { PageProps } from "../../shared/models/PageModel";
// eslint-disable-next-line import/no-unresolved
import * as Contentful from "../../../@types/contentful" ;
import useContentful from "../../shared/hooks/useContentful";
import * as MarkDownConverter from "../../shared/converters/markdown.converter";
import useScrollPosition from "../../shared/hooks/useScrollPosition";

const Contact = ({ updateActiveNav }: PageProps) => {
	const ref: React.RefObject<HTMLElement> = useRef(null);
	const scrollPosition = useScrollPosition();
	const { data } = useContentful<Contentful.KeyValueBasedSection>("contact-section", "keyValueBasedSection");

	useEffect(() => {
		if (ref.current !== null && !!updateActiveNav) {
			if (scrollPosition + (window.visualViewport.height / 2) >= ref.current.offsetTop) {
				updateActiveNav(`#${ref.current.id}`);
			}
		}
	}, [scrollPosition, updateActiveNav]);

	return (
		<section ref={ref} id="contact" className="Container Contact-container">
			<h2>{ data?.title }</h2>
			<div className="Contact-info">
				{ data?.data?.map((item, index) => (
					<React.Fragment key={index}>
						<span>{ item.key }</span>
						<span>{ MarkDownConverter.transform(item.value) }</span>
					</React.Fragment>
				))}
			</div>
		</section>
	);
};

export default Contact;
