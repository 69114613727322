import React from "react";

import "./not-found.css";
import useContentful from "../../shared/hooks/useContentful";
// eslint-disable-next-line import/no-unresolved
import * as Contentful from "../../../@types/contentful";

const NotFound = () => {
	const { data } = useContentful<Contentful.Page>("404-page", "page");

	return (
		<section className="Container NotFound-container">
			<h5>{ data?.subtitle }</h5>
			<h1>{ data?.title }</h1>
			<br />
			{ data?.body?.value }
		</section>
	);
};

export default NotFound;
