import React, { useRef, useEffect } from "react";

import "./portfolio.css";
import { PageProps } from "../../shared/models/PageModel";
import useContentful from "../../shared/hooks/useContentful";
// eslint-disable-next-line import/no-unresolved
import * as Contentful from "../../../@types/contentful" ;
import * as MarkdownConverter from "../../shared/converters/markdown.converter";
import useScrollPosition from "../../shared/hooks/useScrollPosition";

const Projects = ({ updateActiveNav }: PageProps) => {
	const ref: React.RefObject<HTMLElement> = useRef(null);
	const scrollPosition = useScrollPosition();
	const { data } = useContentful<Contentful.ListBasedSection>("projects_section", "listBasedSection");

	useEffect(() => {
		if (ref.current !== null && !!updateActiveNav) {
			if (scrollPosition + (window.visualViewport.height / 2) >= ref.current.offsetTop) {
				updateActiveNav(`#${ref.current.id}`);
			}
		}
	}, [scrollPosition, updateActiveNav]);

	return (
		<section ref={ref} id="projects" className="Container Projects-container">
			<h5>{ data?.subtitle }</h5>
			<h2>{ data?.title }</h2>
			<div className="Projects-list">
				{ MarkdownConverter.transform(data?.list) }
			</div>
		</section>
	);
};

export default Projects;
