import React from "react";
import SVG from "react-inlinesvg";

// eslint-disable-next-line import/no-unresolved
import { AssetFields } from "../../../@types/contentful";

export function transform(icon?: AssetFields, props?: any): React.ReactNode {
	const { url, title, description } = icon ?? {};
	return (
		icon !== undefined
			? <SVG
				src={url}
				title={title}
				description={description}
				fill="currentColor"
				{...props}
			>
				<img src={url} alt={description} title={title} {...props}/>
			</SVG>
			: <></>
	);
}
