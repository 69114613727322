import { Asset, Entry } from "contentful";
import { Document } from "@contentful/rich-text-types";
import * as AssetConverter from "./asset.converter";
import * as RichTextConverter from "./richtext.converter";

type TransformedEntry<T> = {
	value: Entry<T>,
	success: boolean,
}

export function transform<T extends object>(entry: Entry<T>): TransformedEntry<T> {
	let success = false;
	if (!isValid(entry)) {
		console.warn(`Invalid entry ${JSON.stringify(entry)}. Entry was not transformed.`);
		return { value: entry, success };
	}

	const transformed: Entry<T> = { ...entry};
	const type: string = transformed.sys.type;
	if (type === "Entry") {
		const fields: T = transformed.fields;
		Object.keys(fields).forEach((key) => {
			const value: any = fields[key];
			if (typeof value === "object") {
				if (Array.isArray(value)) {
					Object.assign(value, value.map(item => (typeof item === "object" ? transform(item).value : item)));
				} else if (RichTextConverter.isRichText(value)) {
					Object.assign(value, { "value": RichTextConverter.transform(value as Document) });
				} else {
					switch ((value as Entry<any>)?.sys?.type) {
					case "Asset":
						Object.assign(value, AssetConverter.transform(value as Asset));
						break;
					case "Entry":
						Object.assign(value, transform(value as Entry<any>).value);
						break;
					default:
						console.warn("TODO: unhandled case", key, value); // TODO: use ReferenceConverter
						break;
					}
				}
			}
			Object.assign(transformed, { [key]: value });
		});
	}
	success = true;

	return { value: transformed, success };
}

export function isValid<T>(entry: Entry<T>): entry is Entry<T> {
	if (!entry) return false;

	return 	entry.sys !== undefined &&
						entry.fields !== undefined &&
						entry.metadata !== undefined;
}
