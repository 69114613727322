import pdf from "../../assets/CV_EN_Victor_Antonio_Hernandez_Nuñez.pdf";

const Interaction = () => (
	<div className="Interaction-container btn-container">
		<a href={pdf} download className="btn">
      Download CV
		</a>
		<a href="#contact" className="btn btn-primary">
      Get in touch!
		</a>
	</div>
);

export default Interaction;
