import React from "react";
import { Link } from "react-router-dom";
import { EntryFields, RichTextContent } from "contentful";
import { Document, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents, Options } from "@contentful/rich-text-react-renderer";

import * as LinkHelper from "../helpers/link.helper";
/**
 * Reference: https://www.contentful.com/developers/docs/javascript/tutorials/rendering-contentful-rich-text-with-javascript/
 */
const options: Options = {
	// renderMark: {
	// 	[MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
	// },
	// renderNode: {
	// 	[BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
	// },

	renderNode: {
		[INLINES. HYPERLINK]: (node, children) => {
			const anchorAttrs = {
				target: "_blank",
				rel: "noopener noreferrer"
			};

			return LinkHelper.isInternal(node.data.uri)
				? <Link to={node.data.uri}>{ children }</Link>
				: <a href={node.data.uri} {...anchorAttrs}>{ children }</a>;
		}
	}
};
export function isRichText(value: any): boolean {
	return	(value as EntryFields.RichText).nodeType !== undefined &&
					(value as EntryFields.RichText).content !== undefined &&
					(value as EntryFields.RichText).data !== undefined;
}

export function transform(richText: Document): React.ReactNode {
	return documentToReactComponents(richText, options);
}
