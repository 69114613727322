import React from "react";

import "./footer.css";
import useContentful from "../../shared/hooks/useContentful";
// eslint-disable-next-line import/no-unresolved
import * as Contentful from "../../../@types/contentful";

const Footer = () => {
	const { data } = useContentful<Contentful.Footer>("footer", "footer");

	return (
		<footer>
			<p className="text-center">{ data?.title }</p>
		</footer>
	);
};

export default Footer;
