import React, { useEffect, useState } from "react";

import "./home.css";
import Header from "../header/Header";
import NavigationBar from "../navigation/NavigationBar";
import Experience from "../experience/Experience";
import Projects from "../portfolio/Projects";
import Skills from "../skills/Skills";
import Certificates from "../certificates/Certificates";
import Education from "../education/Education";
import Contact from "../contact/Contact";
import Footer from "../footer/Footer";

const Home = () => {
	const [activeNav, setActiveNav] = useState("#");
	const updateActiveNav = (nav) => setActiveNav(nav);

	return (
		<>
			<Header updateActiveNav={updateActiveNav} />
			<NavigationBar activeNav={activeNav} updateActiveNav={updateActiveNav} />
			<Experience updateActiveNav={updateActiveNav} />
			<Projects updateActiveNav={updateActiveNav} />
			{/* <Skills updateActiveNav={updateActiveNav}/> */}
			{/* <Certificates updateActiveNav={updateActiveNav}/> */}
			<Education updateActiveNav={updateActiveNav} />
			<Contact updateActiveNav={updateActiveNav} />
			<Footer />
		</>
	);
};

export default Home;
