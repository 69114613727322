/* eslint-disable import/no-unresolved */
import React, { useRef, useEffect } from "react";

import "./experience.css";
import { PageProps } from "../../shared/models/PageModel";
// eslint-disable-next-line import/no-unresolved
import * as Contentful from "../../../@types/contentful" ;
import useContentful from "../../shared/hooks/useContentful";
import useScrollPosition from "../../shared/hooks/useScrollPosition";
import InfoContainer from "../../shared/components/info-element/InfoContainer";

const Experience = ({ updateActiveNav }: PageProps) => {
	const ref: React.RefObject<HTMLElement> = useRef(null);
	const scrollPosition = useScrollPosition();
	const { data } = useContentful<Contentful.CardBasedSection>("experience-section", "cardBasedSection");

	useEffect(() => {
		if (ref.current !== null && !!updateActiveNav) {
			if (scrollPosition + (window.visualViewport.height / 2) >= ref.current.offsetTop) {
				updateActiveNav(`#${ref.current.id}`);
			}
		}
	}, [scrollPosition, updateActiveNav]);

	return (
		<section ref={ref} id="experience" className="Container Experience-container">
			<h5>{ data?.subtitle }</h5>
			<h2>{ data?.title }</h2>
			{ data?.description?.value }
			<InfoContainer data={data?.cards} />
		</section>
	);
};

export default Experience;
