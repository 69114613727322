import React, { useState } from "react";
// eslint-disable-next-line import/no-unresolved
import { Card } from "../../../../@types/contentful";
import InfoBadge from "./InfoBadge";
import InfoCard from "./InfoCard";

type InfoContainerProps = {
	data?: Card[],
}
const InfoContainer = ({data}: InfoContainerProps) => {
	const [activeIndex, setActiveIndex] = useState<number>(0);

	const isActive = (index: number) => activeIndex === index;

	// TODO: could be a single map
	return (
		<div className="InfoContainer">
			<div className="InfoBadgeContainer">
				{ data?.map((card, index) => (
					<InfoBadge
						key={`info-badge-${card.id}`}
						data={card}
						className={[
							`${isActive(index) ? "active" : ""}`
						].join(" ")}
						onClick={() => (setActiveIndex(index))}
					/>
				))}
			</div>
			<div className="InfoCardContainer stack">
				{ data?.map((card, index) => {
					const { id, title, subtitle, caption, description } = card;
					const compactCard: Card = { id, title, subtitle, caption, description };
					return (
						// TODO: check tallest card and use its height as container height
						<InfoCard
							key={`info-card-${compactCard.id}`}
							data={compactCard}
							className={[
								"prominent",
								"compact",
								`${isActive(index) ? "active" : ""}`,
							].join(" ")}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default InfoContainer;
