import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import "./App.css";
import Home from "./components/home/Home";
import NotFound from "./components/not-found/NotFound";

function App() {
	const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
	const toggleDarkTheme = (shouldAdd: boolean) => document.body.classList.toggle("dark", shouldAdd);

	toggleDarkTheme(prefersDark.matches);
	prefersDark.addEventListener("change", (mediaQuery) => toggleDarkTheme(mediaQuery.matches));

	return (
		<Routes>
			<Route
				id="main"
				path="/"
				element={
					<Home />
				}
				caseSensitive={false}
				// loader={}
				// action={}
				// hasErrorBoundary={true}
				// shouldRevalidate={}
				// handle={}
				// index={}
				// errorElement={}
			/>
			<Route
				id="not-found"
				path="*"
				element={<NotFound />}
			/>
		</Routes>
	);
}

export default App;
