/* eslint-disable import/no-unresolved */
import { useEffect, useState, useRef } from "react";
import { CreateClientParams, createClient, EntryCollection } from "contentful";
import { CONTENT_TYPE } from "../../../@types/generated/contentful";
import * as EntryConverter from "../converters/entry.converter";

type QueryParams = {
	content_type: CONTENT_TYPE,
	"fields.id": string;
	limit?: number;
	include?: number;
	// linking_field?: string;
	// entry_id?: string;
};

const useContentful = <T extends object>(id: string, contentType: CONTENT_TYPE) => {
	const [content, setContent] = useState<T>();
	const [error, setError] = useState<any>();
	const controller = useRef(new AbortController());

	useEffect(() => {
		const clientParams: CreateClientParams = {
			space: process.env.CONTENTFUL_SPACE_ID ?? "",
			accessToken: process.env.CONTENTFUL_DELIVERY_ACCESS_TOKEN ?? "",
			environment: process.env.CONTENTFUL_ENVIRONMENT ?? "master",
			// insecure?: boolean;
			// host?: string;
			// basePath?: string;
			// httpAgent?: any;
			// httpsAgent?: any;
			// proxy?: AxiosProxyConfig;
			// headers?: any;
			// adapter?: any;
			// application?: string;
			// integration?: string;
			resolveLinks: true,
			// removeUnresolved?: boolean;
			retryOnError: true,
			// logHandler?: (level: ClientLogLevel, data?: any) => void;
			// timeout?: number;
			retryLimit: 3,
		};
		const queryParams: QueryParams = {
			content_type: contentType,
			"fields.id": id,
			limit: 1,
			include: 10,
		};
		createClient(clientParams)
			.getEntries<any>(queryParams)
			.then((entries: EntryCollection<any>) => {
				const entry = entries.items[0];
				const { value, success } = EntryConverter.transform(entry);
				if (!success) {
					setError(`Could not get entry with id: "${id}"`);
				} else {
					setContent(value as T);
				}
			})
			.catch((err: any) => {
				if (err.name === "AbortError") {
					// request was cancelled
					console.error("AbortError");
				} else {
					console.error(`Caught error: Could not get entry with id: "${id}"`, err);
					setError(err);
				}
			});

		return () => {
			controller.current.abort();
		};
	}, []);

	return { data: content, error };
};

export default useContentful;
