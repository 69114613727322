import React, { useRef, useState } from "react";

// eslint-disable-next-line import/no-unresolved
import { AssetFields, Card } from "../../../../@types/contentful";
import * as SVGConverter from "../../converters/svg.converter";
import "./info-element.css";

type InfoBadgeProps = {
	data: Card,
	className?: string,
	isActive?: boolean,
	onClick?: () => void,
}

const InfoBadge = ({data, className, isActive, onClick}: InfoBadgeProps) => {
	// const ref = useRef(null);
	const [active, setActive] = useState(isActive);

	// const handleClick = (data: Card) => {
	// 	console.log("clicked badge", data.id)
	// 	// return data;
	// };

	// console.log(data);
	return (
		// TODO: add event listener for hover to apply "active" styling
		<div className={`InfoBadge${className ? ` ${className}` : ""}`} onClick={onClick}>
			{/* TODO: move to top on mobile and make it scrollable horizontally */}
			<div className="icon-container">
				{ SVGConverter.transform(data.icon as AssetFields, { className: "icon", width: "3em", height: "3em" }) }
			</div>
			{/* TODO: make it look like a tooltip */}
			{/* { !active &&
				<div className="tooltip tail">
					<h4>{ data.shortDescription }</h4>
				</div>
			} */}
		</div>
	);
};

export default InfoBadge;
