import React, { useEffect, useRef } from "react";

import "./header.css";
import HeaderContact from "./HeaderContact";
import Interaction from "./Interaction";
import { PageProps } from "../../shared/models/PageModel";
import useContentful from "../../shared/hooks/useContentful";
// eslint-disable-next-line import/no-unresolved
import * as Contentful from "../../../@types/contentful" ;
import useScrollPosition from "../../shared/hooks/useScrollPosition";

const Header = ({ updateActiveNav }: PageProps) => {
	const ref: React.RefObject<HTMLElement> = useRef(null);
	const scrollPosition = useScrollPosition();
	const { data } = useContentful<Contentful.Header>("header", "header");

	useEffect(() => {
		if (ref.current !== null && !!updateActiveNav) {
			if (scrollPosition + (window.visualViewport.height / 2) >= ref.current.offsetTop) {
				updateActiveNav("#");
			}
		}
	}, [scrollPosition, updateActiveNav]);

	return (
		<header ref={ref}>
			<div className="Container Header-container">
				<h5>{data?.greeting}</h5>
				<h1 className="title">
					{data?.owner?.firstname} {data?.owner?.lastname}
				</h1>
				<h5 className="text-medium-shade">{data?.owner?.caption}</h5>
				<Interaction />
				<img src={data?.owner?.pic?.url} alt={data?.owner?.pic?.description} className="me"></img>
				{/* TODO: pass props for header contact links */}
				<HeaderContact />
			</div>
		</header>
	);
};

export default Header;
