import React, { useState } from "react";
import { TbMapSearch } from "react-icons/tb";

import "./info-element.css";
// eslint-disable-next-line import/no-unresolved
import { AssetFields, Card } from "../../../../@types/contentful";
import * as SVGConverter from "../../converters/svg.converter";

type InfoCardProps = {
	data?: Card,
	className?: string,
}

const InfoCard = ({data, className}: InfoCardProps) => {
	const isExpandable = className?.includes("expandable") && data?.shortDescription && data?.description?.value;
	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<div className={`InfoCard${className ? ` ${className}` : ""}`}>
			<div className="InfoCard-context-container">
				{ SVGConverter.transform(data?.icon as AssetFields, { className: "icon", width: "3em", height: "3em" }) }
				<small className="small-title">{ data?.caption }</small>
			</div>
			<div className="InfoCard-body">
				<div className="description">
					<p>{ data?.shortDescription }</p>
					{ !isExpandable
						? data?.description?.value
						: <>
							<div className="text-right text-expand link" onClick={() => setIsExpanded((prev) => !prev)}>
								{ isExpanded ? data?.collapse?.caseTrue : data?.collapse?.caseFalse }
							</div>
							{ isExpanded && data?.description?.value }
						</>
					}
				</div>
			</div>
			<div className="InfoCard-title-container">
				<h4 className="subtitle">
					{ data?.subtitle?.value }
					{/* TODO: take icon from CMS as user-selectable value */}
					{ data?.subtitle?.value && <TbMapSearch title="View on Google Maps" className="icon link" /> }
				</h4>
				<h2 className="title">{ data?.title }</h2>
			</div>
		</div>
	);
};

export default InfoCard;
