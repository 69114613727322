import React from "react";
import { FaLinkedin, FaGithub, FaGitlab } from "react-icons/fa";

const HeaderContact = () => (
	<div className="HeaderContact-socials">
		<a href="https://www.linkedin.com/in/v-hernandez" title="Linkedin" target="_blank" rel="noreferrer noopener">
			<FaLinkedin />
		</a>
		<a href="https://github.com/vic876vb" title="Github" target="_blank" rel="noreferrer noopener">
			<FaGithub />
		</a>
		<a href="https://gitlab.com/users/vic876vb/groups" title="GitLab" target="_blank" rel="noreferrer noopener">
			<FaGitlab />
		</a>
	</div>
);

export default HeaderContact;
