/* eslint-disable import/no-unresolved */
import React, { useRef, useEffect } from "react";
import { Pagination, EffectCards } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-cards";

import { PageProps } from "../../shared/models/PageModel";
import InfoCard from "../../shared/components/info-element/InfoCard";
// eslint-disable-next-line import/no-unresolved
import * as Contentful from "../../../@types/contentful" ;
import useContentful from "../../shared/hooks/useContentful";
import useScrollPosition from "../../shared/hooks/useScrollPosition";

const Education = ({ updateActiveNav }: PageProps) => {
	const ref: React.RefObject<HTMLElement> = useRef(null);
	const scrollPosition = useScrollPosition();
	const { data } = useContentful<Contentful.CardBasedSection>("education-section", "cardBasedSection");

	useEffect(() => {
		if (ref.current !== null && !!updateActiveNav) {
			if (scrollPosition + (window.visualViewport.height / 2) >= ref.current.offsetTop) {
				updateActiveNav(`#${ref.current.id}`);
			}
		}
	}, [scrollPosition, updateActiveNav]);

	return (
		// TODO: use className="Container Education-container"
		<section ref={ref} id="education" className="Container Experience-container">
			<h5>{ data?.subtitle }</h5>
			<h2>{ data?.title }</h2>
			{ data?.description?.value }
			{/* TODO: consider merging style with ExperienceCard */}
			<Swiper className="ExperienceCard-container"
				modules={[Pagination, EffectCards]}
				pagination={{ clickable: true }}
				scrollbar={{ draggable: true }}
				grabCursor={true}
				effect={"cards"}
				cardsEffect={{ slideShadows: false }}
				// autoHeight={true}
				slidesPerView={1}
				onSlideChange={() => {/* TODO: collapse text-expand */}}
				// onSwiper={(swiper) => console.log(swiper)}
			>
				{ data?.cards?.map((card, index) => (
					<SwiperSlide className="overflow-visible" key={index}>
						<InfoCard
							data={card}
							className="swiper animated expandable"
						/>
					</SwiperSlide>
				))}
			</Swiper>
		</section>
	);
};

export default Education;
